<template>
    <v-container fluid>
        <v-form @submit.prevent="getPushes('search')">
            <v-btn v-show="$vuetify.breakpoint.xsOnly" :to="{name: 'push.create'}"
                   color="indigo" fab fixed top right class="v-btn--add-form-top white--text">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="10">
                                <v-subheader class="headline">{{ $t('search_push') }}</v-subheader>
                            </v-col>
                            <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="12" sm="2" class="text-right">
                                <v-btn :to="{name: 'push.create'}" class="white--text" color="indigo">
                                    {{ $t('create') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12">
                                <ValidationProvider ref="name" rules="min:3"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="name" type="text" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-notification-clear-all"
                                                  :label="$t('push_name')" color="primary"
                                                  clearable></v-text-field>
                                </ValidationProvider>
                            </v-col>

                            <v-col class="py-0" cols="12" sm="6">
                                <v-autocomplete v-model="sent" :disabled="loading" :items="sentItems"
                                                prepend-icon="mdi-checkbox-marked" item-text="name" item-value="id"
                                                :label="$t('sent')" color="primary" clearable></v-autocomplete>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <v-select v-model="sex" :items="sexItems" :disabled="loading" item-text="name"
                                          item-value="id" prepend-icon="mdi-gender-male-female"
                                          :label="$t('sex')" clearable></v-select>
                            </v-col>

                            <v-col class="py-0" cols="12" sm="6">
                                <v-select v-model="ageFrom" :items="ageFromItems" :disabled="loading"
                                          prepend-icon="mdi-face-man" :label="$t('age_from')" color="primary"
                                          clearable></v-select>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <v-select v-model="ageTo" :items="ageToItems" :disabled="loading"
                                          prepend-icon="mdi-face-man" :label="$t('age_from')" color="primary"
                                          clearable></v-select>
                            </v-col>

                            <v-col class="py-0" cols="12" sm="6">
                                <v-dialog ref="datePushesFrom" v-model="datePushesFrom" width="290px">
                                    <v-date-picker ref="pickerTourFrom" v-model="dateFrom"
                                                   :max="dateTo ? $moment(dateTo).format('YYYY-MM-DD') : $moment().add(10, 'y').format('YYYY-MM-DD')"
                                                   :locale="lang" @change="datePushesFrom = false"></v-date-picker>
                                </v-dialog>
                                <ValidationProvider ref="dateFrom" rules="date_format"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="dateFrom" :error-messages="errors"
                                                  :disabled="loading" :label="$t('date_from')"
                                                  prepend-icon="mdi-calendar" color="primary" readonly
                                                  @click.stop="datePushesFrom = !datePushesFrom"
                                                  clearable @click:clear="dateFrom = null"></v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <v-dialog ref="datePushesTo" v-model="datePushesTo" width="290px">
                                    <v-date-picker ref="pickerTourTo" v-model="dateTo"
                                                   :min="dateFrom ? $moment(dateFrom).format('YYYY-MM-DD') : $moment().subtract(10, 'y').format('YYYY-MM-DD')"
                                                   :locale="lang" @change="datePushesTo = false"></v-date-picker>
                                </v-dialog>
                                <ValidationProvider ref="dateTo" rules="date_format"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="dateTo" :error-messages="errors" :disabled="loading"
                                                  :label="$t('date_to')" prepend-icon="mdi-calendar"
                                                  color="primary" readonly clearable
                                                  @click.stop="datePushesTo = !datePushesTo"
                                                  @click:clear="dateTo = null"></v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4">
                        <v-row>
                            <v-col cols="12" sm="3" md="2">
                                <v-select :value="perPage" :items="perPageItems" :disabled="loading"
                                          :label="$t('items_per_page')" prepend-icon="mdi-counter"
                                          @input="perPage = options.itemsPerPage = Number($event)" hide-details
                                          dense></v-select>
                            </v-col>
                            <v-col cols="12" sm="9" md="10"
                                   class="d-flex justify-center justify-sm-end align-self-center">
                                <v-btn type="submit" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly"
                                       color="primary">{{ $t('search') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
        <v-card flat>
            <v-card-text>
                <v-data-table :headers="headers" :items="pushItems" :options.sync="options" :page.sync="page"
                              :items-per-page="perPage" :server-items-length="totalPushes" :sort-by.sync="sortBy"
                              :sort-desc.sync="sortDir" :loading="loading" :locale="lang"
                              :loading-text="$t('loading_please_wait')" class="elevation-1"
                              single-expand :expanded.sync="expanded" item-key="id" show-expand hide-default-footer>
                    <template v-slot:item.is_sent="{ item }">
                        {{ item.is_sent ? $t('yes') : $t('no')}}
                    </template>
                    <template v-slot:item.date_from="{ item }">{{ $moment(item.date_from).format('YYYY-MM-DD HH:mm') }}
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom v-if="!item.planned">
                            <template v-slot:activator="{ on }">
                                <v-icon :disabled="item.planned" v-on="on" class="mr-2" @click="startPush(item)" :title="$t('edit')">
                                    mdi-clock-start
                                </v-icon>
                            </template>
                            <span>{{$t('schedule_departure')}}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon :disabled="item.planned" v-on="on" class="mr-2" @click="editPushes(item)" :title="$t('edit')">
                                    mdi-square-edit-outline
                                </v-icon>
                            </template>
                            <span>{{$t('edit')}}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click="deletePushes(item)" :title="$t('delete')">
                                    mdi-delete-outline
                                </v-icon>
                            </template>
                            <span>{{$t('delete')}}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:expanded-item="{ headers, item}">
                        <td class="pt-4" :colspan="headers.length">
                            <v-row justify="center" class="px-3">
                                <v-col cols="12" class="justify-start">
                                    <p>{{ $t('push_name') }}: {{ item.name }}</p>
                                    <p>{{ $t('body') }}: {{ item.body }}</p>
                                    <p>{{ $t('sex') }}: {{ item.sex.name ? item.sex.name : $t('not_specified')}}</p>
                                    <p>{{ $t('age_from') }}: {{ item.age_from ? item.age_from : $t('not_specified')}}</p>
                                    <p>{{ $t('age_to') }}: {{ item.age_to ? item.age_to : $t('not_specified')}}</p>
                                    <p>{{ $t('start_date_of_sending') }}: {{$moment(item.date_from).format('YYYY-MM-DD HH:mm') }}</p>
                                    <p>{{ $t('end_date_of_sending') }}: {{ item.date_to ? $moment(item.date_to).format('YYYY-MM-DD HH:mm') : null }}</p>
                                    <p>{{ $t('number_of_messages_sent') }}: {{ item.count_sent ? item.count_sent : 0 }}</p>
                                    <p>{{ $t('author_of_creation') }}: {{ item.creator.name }}</p>
                                    <p>{{ $t('link_push') }}: {{ item.link ? item.link : $t('not_specified')}}</p>
                                </v-col>
                            </v-row>
                        </td>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-pagination v-model="page" :total-visible="totalVisiblePage"
                              :length="pageCount" :disabled="loading"></v-pagination>
            </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>

    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mapGetters} from "vuex"

    export default {
        name: "Pushes",
        components: {
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                expanded: [],
                name: null,
                sortBy: "created_at",
                sortDir: false,
                loading: false,
                options: {},
                page: 1,
                pageCount: 0,
                perPage: 5,
                totalVisiblePage: 5,
                totalPushes: 0,
                pushItems: [],
                dateFrom: null,
                datePushesFrom: false,
                dateTo: null,
                datePushesTo: false,
                headers: [
                    {
                        text: '',
                        value: 'data-table-expand',
                        width: 40,
                        class: "handle",
                    },
                    {
                        text: this.$t('push_name'),
                        align: "left",
                        sortable: true,
                        value: "name"
                    },
                    {
                        text: this.$t('sex'),
                        align: "center",
                        sortable: false,
                        value: "sex.name"
                    },
                    {
                        text: this.$t('age'),
                        align: "center",
                        sortable: false,
                        value: "age"
                    },
                    {
                        text: this.$t('sent'),
                        align: "center",
                        sortable: true,
                        value: "is_sent"
                    },
                    {
                        text: this.$t('datetime'),
                        align: "center",
                        sortable: true,
                        value: "date_from"
                    }, {
                        text: this.$t('updated_at'),
                        align: "center",
                        sortable: true,
                        value: "updated_at"
                    },

                    {
                        text: this.$t('actions'),
                        align: "center",
                        value: 'action',
                        sortable: false,
                        width: 120,
                    },
                ],
                sex: null,
                sexItems: [],
                sent: null,
                sentItems: [
                    {
                        id: -1,
                        name: this.$t('no')
                    },
                    {
                        id: 1,
                        name: this.$t('yes')
                    },
                ],
                ageFrom: null,
                ageTo: null,
            }
        },
        computed: {
            ...mapGetters(['lang', 'itemsPerPage', 'perPageItems']),
            ageFromItems() {
                let arr = []
                for (let i = 14; i <= (this.ageTo ? this.ageTo : 100); i++) {
                    arr.push(i)
                }
                return arr;
            },
            ageToItems() {
                let arr = []
                for (let i = (this.ageFrom ? this.ageFrom : 14); i <= 100; i++) {
                    arr.push(i)
                }
                return arr;
            },
        },
        mounted() {
            this.options.itemsPerPage = this.perPage = this.itemsPerPage
            this.getSexes();
        },
        watch: {
            options: {
                handler() {
                    this.getPushes()
                },
                deep: false
            }
        },
        methods: {
            editPushes(item) {
                this.$router.push({
                    name: 'push.edit',
                    params: {
                        id: item.id
                    }
                })
            },
            async getSexes() {
                this.loading = true
                let params = {}


                await this.$http
                    .get("admin/sex", {
                        params: params,
                    })
                    .then(res => {
                        this.sexItems = res.body.data
                    })
                    .catch(err => {
                        this.sexItems = []
                        this.$toastr.error(this.$t('failed_to_get_list_sexes'))
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            async getPushes(type) {
                this.loading = true
                const {
                    sortBy,
                    sortDesc,
                    page,
                    itemsPerPage
                } = this.options

                let params = {}

                if (sortBy[0] !== undefined) {
                    params.sortBy = sortBy[0]
                }
                if (sortDesc[0] !== undefined) {
                    params.sortDir = sortDesc[0] ? 'asc' : 'desc'
                }
                if (page !== undefined) {
                    if (type === 'search') {
                        params.page = 1
                    } else {
                        params.page = page
                    }
                }
                if (itemsPerPage !== undefined) {
                    params.perPage = itemsPerPage
                }
                if (this.name) {
                    params.name = this.name
                }
                if (this.dateFrom) {
                    params.date_from = this.dateFrom
                }
                if (this.dateTo) {
                    params.date_to = this.dateTo
                }
                if (this.ageFrom) {
                    params.ageFrom = this.ageFrom
                }
                if (this.ageTo) {
                    params.ageTo = this.ageTo
                }
                if (this.sent) {
                    params.sent = this.sent
                }
                if (this.sex) {
                    params.sex = this.sex
                }
                await this.$http
                    .get("admin/push", {
                        params: params,
                    })
                    .then(res => {
                        this.pushItems = res.body.data
                        this.page = res.body.meta.current_page
                        this.totalPushes = res.body.meta.total
                        this.pageCount = res.body.meta.last_page
                    })
                    .catch(err => {
                        this.pushItems = []
                        this.totalPushes = 0
                        this.$toastr.error(this.$t('failed_to_get_list_push'))
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            async deletePushes(item) {
                if (confirm(this.$t('delete_push'))) {
                    var _this = this
                    this.loading = true
                    await this.$http
                        .delete(`admin/push/${item.id}`)
                        .then(res => {
                            this.$toastr.success(this.$t('push_has_been_deleted'))
                            this.getPushes()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('push_has_not_been_deleted'))
                            if (err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.loading = false
                        })
                }
            },
            async startPush(item) {
                if (confirm(this.$t('start_push'))) {
                    var _this = this
                    this.loading = true
                    await this.$http
                        .get(`admin/push/${item.id}/start`)
                        .then(res => {
                            this.$toastr.success(this.$t('push_has_been_started'))
                            this.getPushes()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('push_has_not_been_started'))
                            if (err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.loading = false
                        })
                }
            }
        }
    }
</script>
